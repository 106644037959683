<template>
  <div class="info" id="watermark">
    <!-- 所属社区区 -->
    <van-popup v-model="communityShow" position="bottom" >
      <van-picker title="请选择" show-toolbar :columns="selectData.communityList" value-key="label"
                  @confirm="communityConfirm" @cancel="communityShow = false"/>
    </van-popup>
    <!-- 所属小区 -->
    <van-popup v-model="subareaShow" position="bottom" >
      <van-picker title="请选择" show-toolbar :columns="selectData.subAreaOptions" value-key="name"
                  @confirm="subareaConfirm" @cancel="subareaShow = false"/>
    </van-popup>
    <!-- 车辆类型 -->
    <van-popup v-model="carTypeShow" position="bottom" >
      <van-picker title="请选择" show-toolbar :columns="selectData.carTypeList" value-key="label"
                  @confirm="carTypeConfirm"  @cancel="carTypeShow = false"/>
    </van-popup>
    <!-- 车牌类型 -->
    <van-popup v-model="plateTypeShow" position="bottom" >
      <van-picker title="请选择" show-toolbar :columns="selectData.plateTypeList" value-key="label"
                  @confirm="plateTypeConfirm"  @cancel="plateTypeShow = false"/>
    </van-popup>
    <!-- 车牌颜色 -->
    <van-popup v-model="plateColorShow" position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.plateColorList" value-key="label"
                  @confirm="plateColorConfirm" @cancel="plateColorShow = false"/>
    </van-popup>
    <!-- 车身颜色 -->
    <van-popup v-model="colorShow" position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.colorList" value-key="label"
                  @confirm="colorConfirm" @cancel="colorShow = false"/>
    </van-popup>
    <!-- 是否临时车辆 -->
    <van-popup v-model="tempCarShow" position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.tempCarList" value-key="label"
                  @confirm="tempCarConfirm" @cancel="tempCarShow = false"/>
    </van-popup>

    <!-- 选择房屋 -->
    <van-popup v-model="buildingShow" position="bottom">
      <van-cascader v-model="submitData.building" title="请选择" :options="selectData.buildingList"
                    @close="buildingShow = false" @finish="buildingFinish" :field-names="fieldNames"/>
    </van-popup>
      <van-cell-group>
        <van-cell class="custom">
          <template #title>
            <span class="custom-title">车辆信息</span>
          </template>
        </van-cell>
        <van-cell title="所属社区" v-if="isCommunity" :value="selectMsg.communityTitle" @click="communityShow = edit" :clickable="edit"
                  :value-class="{'value-common':selectMsg.communityTitle=='请选择'}" :is-link="edit" required/>
        <van-cell title="所属小区" :value="selectMsg.subAreaTitle" @click="subareaShow = edit" :clickable="edit"
                  :value-class="{'value-common':selectMsg.subAreaTitle=='请选择'}" :is-link="edit" required/>
        <van-cell title="车辆类型" :value="selectMsg.carTypeTitle" @click="carTypeShow = edit" :clickable="edit"
                  :value-class="{'value-common':selectMsg.carTypeTitle=='请选择'}" :is-link="edit" required/>
        <van-cell title="车牌号码" class="carNumber" required>
          <van-field clearable clear-trigger="always" v-model="submitData.carNumber" :readonly="!edit" input-align="right" placeholder="请输入">
            <template #right-icon>
              <van-uploader :disabled="!edit" :after-read="cardIdIdentified">
                <van-icon size="25" color="#BBB" name="photograph"/>
              </van-uploader>
            </template>
          </van-field>
        </van-cell>
        <van-cell title="车辆品牌">
          <van-field clearable clear-trigger="always" v-model="submitData.carBrand" :readonly="!edit" input-align="right" placeholder="请输入"/>
        </van-cell>
        <van-cell title="所属人" required>
          <van-field clearable clear-trigger="always" v-model="submitData.carUserName" @blur="houseBlur" :readonly="!edit" input-align="right" placeholder="请输入"/>
        </van-cell>
        <van-cell title="联系方式" required>
          <van-field clearable clear-trigger="always" v-model="submitData.mobile" @blur="houseBlur" :readonly="!edit" input-align="right" placeholder="请输入"/>
        </van-cell>
        <van-cell title="居住房屋" v-if="houseInfo !== null">
          <van-field clearable clear-trigger="always" v-model="submitData.fullName" readonly input-align="right" placeholder="居住房屋"/>
        </van-cell>
        <p v-if="houseInfo !== null && edit" style="line-height: 0.7rem;padding: 0.1rem 0 0.1rem 0.5rem;margin: 0;color: #F56C6C;background-color: #f5f5f5;">请选择小区并填写所属人员和联系方式来搜索房屋</p>
        <van-cell title="居住房屋" :value="selectMsg.buildingStr" v-if="houseInfo == null" @click="buildingShow = edit"
                  :value-class="{'value-common':selectMsg.buildingStr=='请选择'}" :is-link="edit" />
        <van-cell title="车牌类型" :value="selectMsg.plateTypeStr" @click="plateTypeShow = edit"
                  :value-class="{'value-common':selectMsg.plateTypeStr=='请选择'}" :is-link="edit"/>

        <van-cell title="车牌颜色" :value="selectMsg.plateColorStr" @click="plateColorShow = edit"
                  :value-class="{'value-common':selectMsg.plateColorStr=='请选择'}" :is-link="edit" />

        <van-cell title="车身颜色" :value="selectMsg.colorStr" @click="colorShow = edit"
                  :value-class="{'value-common':selectMsg.colorStr=='请选择'}" :is-link="edit" />
        <van-cell title="车辆描述" :border="false" class="cellClass" />
        <van-field type="textarea" clearable :readonly="!edit" clear-trigger="always" v-model="submitData.remark" placeholder="请输入" style="text-align: right" rows="1" autosize/>
      </van-cell-group>
      <van-row class="btns">
        <van-col :span="12" v-if="edit" class="complete">
          <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
        </van-col>
        <van-row v-else>
          <van-col :span="11" class="delete">
            <van-button plain size="large" round @click="deleteClick">删除</van-button>
          </van-col>
          <van-col :span="11">
            <van-button type="info" size="large" @click="update">编辑</van-button>
          </van-col>
        </van-row>
      </van-row>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {dataCheck, formatterDateTimeWithOutSec} from "@/utils/utils"
import {getImageStream} from '@/utils/index'
import {getBelongCommunity} from '@/utils/common'

export default {
  data() {
    return {
      active: 1,
      finished: false,
      illParkList: [],
      dataLoading: false,
      totalCount: 0,
      page: 0,
      edit: false,
      houseVisible: false,
      detailData: {},
      loading: false,
      // 社区选择框是否显示
      isCommunity: false,
      // 社区选择框
      communityShow: false,
      //小区选择框
      subareaShow: false,
      //车辆类型选择框
      carTypeShow: false,
      //车牌类型选择框
      plateTypeShow: false,
      //车牌颜色选择框
      plateColorShow: false,
      //车身颜色选择框
      colorShow: false,
      //是否临时车辆选择框
      tempCarShow: false,
      //房屋弹出框
      buildingShow: false,
      selectMsg: {
        communityTitle: '请选择',
        subAreaTitle:'请选择',
        carTypeTitle: '请选择',
        //是否是临时车
        tempCarStr: '否',
        //车牌类型
        plateTypeStr:'请选择',
        //车牌颜色
        plateColorStr:'请选择',
        //颜色
        colorStr:'请选择',
        // 房屋
        buildTypeStr: '请选择',
      },
      submitData: {
        id: 0,
        sId: 0,
        scId:"",//device_subarea_car Id
        //车辆
        carType: '',
        //车牌号码
        carNumber: '',
        //车辆品牌
        carBrand:'',
        //所属人
        carUserName: '',
        //所属人联系电话
        mobile: '',
        //是否是临时车
        tempCar: '0',
        //车牌类型
        plateType:'请选择',
        //车牌颜色
        plateColor:'请选择',
        //颜色
        color:'请选择',
        //社区
        community: '',
        //小区
        subarea:'',
        //备注
        remark:'',
        //用户id
        userId:'',
        //居住房屋
        fullName: '',
        building: '',
        buildingId: '',
        unitId: ''
      },
      selectData: {
        carTypeList: [],
        tempCarList:[{value:'0',label:'否'},{value:'1',label:'是'}],
        plateTypeList:[],
        plateColorList:[],
        colorList:[],
        communityList: [],
        subAreaOptions:[],
        buildingList: []
      },
      fileList: [],
      houseInfo: [],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      }
    }
  },
  components: {
    topBar
  },
  created() {
    sessionStorage.setItem('changeType', 6)
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.submitData.id = this.$route.query.id || ''
    this.submitData.subarea = this.$route.query.subarea || ''
    this.submitData.userId = this.$route.query.userId || ''
    document.querySelector('html').style.backgroundColor = '#fff'
    this.init()
    if(this.$globalData.userInfo.orgId == this.$globalData.userInfo.street) {
      console.log('111',this.$globalData);
      this.isCommunity = true
      let _this = this
      getBelongCommunity(this.$globalData.userInfo.orgId,function (list) {
        console.log('社区',list);
        _this.selectData.communityList = list
      })
    }
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  },
  methods: {
    selectInfo(id) {
      this.$router.push({path: "/car-illPark?id=" + id});
    },
    imgUrl(img) {
      if (img != null) {
        return getImageStream(img)
      } else {
        return require('@/assets/img/flower.png')
      }
    },
    tabsChange(e) {
      if (e == "2") {
        this.getIllParkList();
      }
    },
    cardIdIdentified(e) {
      let formdata = new FormData();
      formdata.append("file", e.file)
      this.$http({
        url: this.$http.adornUrl('/wxapp/home/file/ocr'),
        method: 'post',
        headers: {
          appid: 'wxcd8dbbf3f2ba3b14',
          type: 3
        },
        data: formdata
      }).then(({data}) => {
        if (data.code == 0) {
          this.submitData.carNumber = data.result
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 初始化
    init() {
      if(this.$globalData.userInfo.orgId != this.$globalData.userInfo.street) {
        this.getSubareaList()
      }
      //车辆类型
      this.getDictTypeList('carType')
      //车牌类型
      this.getDictTypeList('licencePlateType')
      //车牌颜色
      this.getDictTypeList('licencePlateColor')
      //车身颜色
      this.getDictTypeList('carColor')
      //房屋
      this.getBuildingList()
      let nowDate = new Date()
      this.maxDate = nowDate
      this.selectMsg.inspectionTime = formatterDateTimeWithOutSec(nowDate)
      this.submitData.inspectionTime = nowDate
      this.houseInfo = []
      if (this.submitData.id) {
        this.edit = false
        this.houseVisible = true
        this.getInfo()
        // this.getIllParkList()
      } else {
        this.edit = true
      }

    },
    getIllParkList() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.page++;
      this.$http({
        url: this.$http.adornUrl('/wxapp/device/car/mistake/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: 5,
          carId: this.submitData.id,
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataLoading = false
          if (this.illParkList.length == data.page.totalCount) {
            this.$toast.clear();
            this.finished = true;
            return;
          }
          if (this.page == 1 && data.page.list.length == 0) {
            this.illParkList = []
          }
          this.illParkList = this.illParkList.concat(data.page.list);
          this.totalCount = data.page.totalCount;
        } else {
          this.$toast.fail(data.msg)
        }
        this.$toast.clear();
        this.loading = false;
      })
    },
    // 获取小区列表
    getSubareaList(communityId) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: communityId || this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.subAreaOptions = data.subAreaList
          if (this.submitData.carUserName && this.submitData.mobile && this.submitData.subarea) {
            this.houseBlur()
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取字典
    getDictTypeList(code) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual'),
        method: 'get',
        params: this.$http.adornParams({
          code: code
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (code === 'carType') {
            this.selectData.carTypeList = data.virtualDicts
          } else if (code === 'licencePlateType') {
            this.selectData.plateTypeList = data.virtualDicts
          } else if (code === 'licencePlateColor') {
            this.selectData.plateColorList = data.virtualDicts
          } else if (code === 'carColor') {
            this.selectData.colorList = data.virtualDicts
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取房屋列表
    getBuildingList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/liveWhichTree'),
        method: 'get',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.buildingList = data.liveWhichTree
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    buildingFinish(value) {
      if (value) {
        let options = value.selectedOptions
        this.submitData.detailedAddressValue = options.map(item => {
          return item.value
        })
        options.map(val => {
          if (val.depth == 1) {
            this.submitData.subarea = val.value
          }
          if (val.depth == 2) {
            this.submitData.buildingId = val.value
          }
          if (val.depth == 3) {
            this.submitData.unitId = val.value
          }
        })
        this.selectMsg.buildingStr = ''
        let houseId = -1;
        let option = options[options.length - 1];
        if (option.depth == 2 && option.children == null) {
          houseId = option.parameter
        } else if (option.depth == 3 && option.children == null) {
          houseId = -1
        } else if (option.depth == 4 && option.children == null) {
          houseId = option.value
        } else {
          houseId = -1
        }
        this.submitData.houseId = houseId
        if (option.depth == 2 && option.children == null) {
          houseId = option.parameter
        }
        options.map(item => {
          this.selectMsg.buildingStr += item.label
        })
      }
      this.buildingShow = false
    },
    // 通过姓名手机号获取房屋
    houseBlur() {
      if (this.submitData.carUserName && this.submitData.mobile && this.submitData.subarea) {
        this.$http({
          url: this.$http.adornUrl('/wxapp/user/getPhoneAndIdNumberAndSubarea'),
          method: 'get',
          params: this.$http.adornParams({
            'mobile': this.submitData.mobile,
            'name': this.submitData.carUserName,
            'subarea': this.submitData.subarea
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.houseInfo = data.data
            if (this.houseInfo !== null) {
              this.submitData.buildingId = data.data.buildingId
              this.submitData.unitId = data.data.unitId
              this.submitData.houseId = data.data.id
              this.submitData.fullName = data.data.fullName
            }
          }
        })
      }
    },
    update() {
      this.edit = true
      this.houseInfo = null
      this.houseVisible = false
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/devicecar/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.submitData.id,
          subarea: this.submitData.subarea
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectMsg.subAreaTitle = data.deviceCar.subareaStr
          this.submitData.subarea = data.deviceCar.subarea
          this.selectMsg.carTypeTitle = data.deviceCar.typeStr
          this.submitData.carType = data.deviceCar.type
          this.submitData.fullName = data.deviceCar.fullName || '无'
          this.submitData.carNumber = data.deviceCar.carNumber
          this.submitData.carBrand = data.deviceCar.brand
          this.submitData.carUserName = data.deviceCar.userName
          this.submitData.mobile = data.deviceCar.mobile
          this.selectMsg.plateTypeStr = data.deviceCar.licencePlateTypeStr
          this.submitData.plateType = data.deviceCar.licencePlateType
          this.selectMsg.plateColorStr = data.deviceCar.licencePlateColorStr
          this.submitData.plateType = data.deviceCar.licencePlateColor
          this.selectMsg.colorStr = data.deviceCar.colorStr
          this.submitData.color = data.deviceCar.color
          this.submitData.tempCar = data.deviceCar.temporary.toString()
          this.selectMsg.tempCarStr = data.deviceCar.temporary == 1 ? '是' : '否'
          this.submitData.remark = data.deviceCar.remark
          this.submitData.sId = data.deviceCar.sid
          if (!this.edit) {
            this.houseInfo = []
          }
          this.selectMsg.buildingStr = data.deviceCar.fullName
          this.submitData.buildingId = data.deviceCar.buildingId
          this.submitData.unitId = data.deviceCar.unitId
          this.submitData.houseId = data.deviceCar.houseId
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 提交
    submit() {
      if (this.submitData.subarea === '') {
        return this.$toast.fail('失败:请选择所属小区');
      }
      if (this.submitData.carType === '') {
        return this.$toast.fail('失败:请选择车辆类型');
      }
      if (this.submitData.carNumber === '') {
        return this.$toast.fail('失败:车牌号码不能为空');
      }
      if (this.submitData.carUserName === '') {
        return this.$toast.fail('失败:所属人不能为空');
      }
      if (this.submitData.mobile === '') {
        return this.$toast.fail('失败:联系方式不能为空');
      }
      let tempCar = Number(this.submitData.tempCar)
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/devicecar/${this.submitData.id ? 'update' : 'save'}`),
        method: 'post',
        data: {
          id: this.submitData.id,
          scId: this.submitData.scId || '',
          userId: this.submitData.userId,
          //车辆
          type: this.submitData.carType,
          //车牌号码
          carNumber: this.submitData.carNumber,
          //所属人
          userName: this.submitData.carUserName,
          //所属人联系电话
          mobile: this.submitData.mobile,
          //车牌类型
          licencePlateType: this.submitData.plateType == '请选择' ? '' : this.submitData.plateType,
          //车牌颜色
          licencePlateColor: this.submitData.plateColor == '请选择' ? '' : this.submitData.plateColor,
          //颜色
          color: this.submitData.color == '请选择' ? '' : this.submitData.color,
          //是否是临时车
          temporary: tempCar,
          //小区
          subarea: this.submitData.subarea,
          //楼幢
          buildingId: this.submitData.buildingId,
          //单元
          unitId: this.submitData.unitId,
          //房屋
          houseId: this.submitData.houseId,
          //房屋名
          houseName: this.submitData.fullName,
          //车辆品牌
          brand: this.submitData.carBrand == '请输入' ? '' : this.submitData.carBrand,
          //备注
          remark: this.submitData.remark
        }
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.submitData.id ? '修改成功' : '新增成功'}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                sessionStorage.setItem('showInfo', this.submitData.userId)
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    deleteClick() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/devicecar/delete"),
        method: "post",
        data: this.$http.adornData([this.submitData.sId], false),
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.success("删除成功");
          sessionStorage.setItem('showInfo', this.submitData.userId)
          this.$router.go(-1);
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    // 所在社区区选择
    communityConfirm(value) {
      if (value) {
        this.selectMsg.communityTitle = value.label
        this.submitData.community = value.value
        this.getSubareaList(value.value)
      }
      this.communityShow = false
    },
    // 所在小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.subAreaTitle = value.name
        this.submitData.subarea = value.id
      }
      this.subareaShow = false
    },
    // 车辆类型
    carTypeConfirm(value) {
      if (value) {
        this.selectMsg.carTypeTitle = value.label
        this.submitData.carType = value.value
      }
      this.carTypeShow = false
    },
    // 车牌类型
    plateTypeConfirm(value) {
      if (value) {
        this.selectMsg.plateTypeStr= value.label
        this.submitData.plateType = value.value
      }
      this.plateTypeShow = false
    },
    // 车牌颜色
    plateColorConfirm(value) {
      if (value) {
        this.selectMsg.plateColorStr = value.label
        this.submitData.plateColor = value.value
      }
      this.plateColorShow = false
    },
    // 车身颜色
    colorConfirm(value) {
      if (value) {
        this.selectMsg.colorStr = value.label
        this.submitData.color = value.value
      }
      this.colorShow = false
    },
    // 是否临时车辆
    tempCarConfirm(value) {
      if (value) {
        this.selectMsg.tempCarStr = value.label
        this.submitData.tempCar = value.value
      }
      this.tempCarShow = false
    }
  },
}
</script>
<style lang="scss" scoped>
.info {
  .van-cell {
    line-height: 55px;
  }
  .carNumber {
    ::v-deep .van-cell__value {
      line-height: 46px;
    }
    ::v-deep .van-field__right-icon {
      transform: translateY(6px);
    }
  }
}
.btns {
  //position: absolute;
  bottom: 40px;
  .complete {
    .van-button {
      width: 690px;
      background: #4581F8;
    }
  }
  .delete {
    margin-right: 40px;
    .van-button {
      color: #4581F8;
      background: rgba(69,129,248,0);
      border-radius: 8px;
      border: 2px solid #4581F8;
    }
  }
}
p {
  font-size: 28px;
}
</style>
